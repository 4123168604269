<template>
  <div>
    <div class="table_common">
      <el-form label-width="100px" class="service_nav">
        <div class="activit">
          <!-- <div class="activity"> -->
          <el-form-item label="订单编号:">
            <el-input v-model="form.orderNum" @input="searchInput" maxlength="20"></el-input>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="activity"> -->
          <el-form-item label="订单类型:" class="select_class1">
            <el-select placeholder="" class="select_class" v-model="form.orderType">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="activity"> -->
          <el-form-item label="客户名称:">
            <el-input v-model="form.applicantCompany"></el-input>
          </el-form-item>
          <el-form-item label="目标客户名称:" class="target_name_class">
            <el-input v-model="form.subjectName"></el-input>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="activity2"> -->
          <el-form-item label="订单状态:" class="select_class1">
            <el-select placeholder="" class="select_class" v-model="form.orderState">
              <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="activity2"> -->
          <el-form-item label="开始日期:">
            <el-date-picker v-model="form.createDate" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
              :editable="false">
            </el-date-picker>
          </el-form-item>
          <!-- </div> -->
          <!-- <div class="activity2"> -->
          <el-form-item label="完成日期:">
            <el-date-picker v-model="form.finishDate" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
              :editable="false">
            </el-date-picker>
          </el-form-item>
          <!-- </div> -->
        </div>
        <div class="search_boxs flex_center_between_box">
          <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
            <div class="search_button_box" @click="searchForm()">
              <i class="iconfont icon-10sousuo"></i>
            </div>
          </el-tooltip>
          <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
            <div class="reset_button_box" @click="cleargetDate()">
              <i class="iconfont icon-shuaxin"></i>
            </div>
          </el-tooltip>
        </div>
      </el-form>
      <el-form label-width="100px" class="service_main">
        <div class="service_main_header">
          <div class="orderscentre">订单中心</div>
          <el-button class="anniu data_export">数据导出</el-button>
        </div>

        <el-table :data="tableData" border :stripe="true" style="width: 100%" @selection-change="selectionLineChangeHandle" :key="onClickExpansionIndexkey">
          <el-table-column prop="orderNum" label="订单编号" width="180"> </el-table-column>
          <el-table-column prop="orderTypeName" label="订单类型" width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.orderType==1">评级服务</div>
              <div v-else-if="scope.row.orderType==2">监踪服务</div>
              <div v-else-if="scope.row.orderType==3">法律服务</div>
              <div v-else-if="scope.row.orderType==4">认证服务</div>
              <div v-else-if="scope.row.orderType==5">注册认证服务</div>
              <div v-else-if="scope.row.orderType==6">发票认证服务</div>
            </template>
          </el-table-column>
          <el-table-column prop="applicantCompany" label="客户名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="subjectName" label="目标名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createDate" label="开始日期" show-overflow-tooltip></el-table-column>
          <el-table-column prop="finishDate" label="完成日期" show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderState" label="订单状态">
            <template slot-scope="scope">
              <div v-if="scope.row.orderState==1">待办</div>
              <div v-else-if="scope.row.orderState==2">进行中</div>
              <div v-else-if="scope.row.orderState==3">已完成</div>
              <div v-else-if="scope.row.orderState==4">失败</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" :width="width150">
            <template slot-scope="scope">
              <el-tooltip class="particulars" effect="light" content="详情" placement="top">
                <i class="iconfont icon-shuxingliebiaoxiangqing" @click="a(scope.row)"></i>
              </el-tooltip>
              <el-tooltip class="particulars" effect="light" content="撤销" placement="top">
                <i class="iconfont icon-chexiao2x" @click="chehui(scope.row)"></i>
              </el-tooltip>
              <el-tooltip class="particulars" v-if="scope.row.report != null" effect="light" content="下载报告" placement="top">
                <i class="iconfont icon-xiazai" @click="loadingFile(scope.row)"></i>
              </el-tooltip>
              <el-tooltip class="particulars_class" v-else effect="light" content="下载报告" placement="top">
                <i class="iconfont icon-xiazai"></i>
              </el-tooltip>
            </template>

          </el-table-column>
        </el-table>
        <Page :totalFont="true" :total='page.total' :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>

      </el-form>
    </div>
    <el-dialog title="订单详情" :visible.sync="dialogVisible2" :close-on-click-modal="false" class="dialog_width500" id="jzfw2">
      <el-form label-width="100px" class="f2" id="jzfw">
        <el-form-item label="订单编号:" class="retained_profits">
          <el-input class="mc" disabled v-model="form1.orderNum"></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" class="retained_profits">
          <el-input class="mc" disabled v-model="form1.subjectName"></el-input>
        </el-form-item>
        <el-form-item label="监踪周期:" class="retained_profits">
          <el-input class="mc" disabled v-model="form1.trackCycle" @input="formInput1" maxlength="5"></el-input>
        </el-form-item>
        <el-form-item label="监踪频次:" class="retained_profits">
          <el-input class="mc" disabled v-model="form1.trackFrequency" @input="formInput2" maxlength="5"></el-input>
        </el-form-item>
        <el-form-item label="监踪状态:" class="retained_profits">
          <el-input class="mc" disabled v-model="form1.orderTypeName"></el-input>
        </el-form-item>
        <el-form-item label="最后更新时间:" class="retained_profits" v-show="this.dioasc5" :class="exceptArrivalClass ? 'exceptArrivalClass' : ''">
          <el-date-picker v-model="form1.lastUpdateTime" prefix-icon="iconfont icon-riqi" type="date" value-format="yyyy-MM-dd" @input="exceptArrivalChange1()" :picker-options="applyDateChoose"
            :editable="false">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="最后更新时间:" class="retained_profits" v-show="!this.dioasc5" id="zhgxsj">
          <el-input class="mc" disabled v-model="form1.lastUpdateTime"></el-input>
        </el-form-item>
        <el-form-item label="上传报告:" class="add_invoice_messages retained_profits2 retained_profits3" v-show="uploadFormShow">
          <el-upload ref="uploadForm" action="fakeaction1" list-type="picture-card" v-model="file" :http-request="handleUploadFile1" class="upload-demo" accept=".excal,.xlsx,.docx,.xls">
            <div class="upload_box">
              <i class="el-icon-plus"></i>
            </div>
            <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
          </el-upload>
        </el-form-item>
        <div v-show="file&&uploadFormShow" class="invoiceUrlClass" style="">
          <ul>
            <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
              <img :src="jpg + item">
              <div class="close_icon" @click="deleteUpload(index)">
                <i class="iconfont icon-yuyinguanbi"></i>
              </div>
            </li>
          </ul>
        </div>
        <el-form-item label="上传报告:" v-show="!uploadFormShow" class="reportUrl_class">
          <!-- <el-button v-show="!uploadFormShow" class="download_button flex_center" @click="loadingFile1()">
                <i class="iconfont icon-xiazai"></i>
                <span>下载报告</span>
            </el-button> -->
          <ul class="invimg_list flex_wrap">
            <li v-for=" (item,index) in files" :key="index" class="flex_center">
              <img :src="jpg + item">
            </li>
          </ul>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog-footer2" v-show="!this.dioasc2">
        <el-button class="footer_button2" round @click="dialogVisible2=false">关 闭</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-show="this.dioasc2">
        <el-button class="footer_button2" round @click="dialogVisible2=false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="tijiao2()">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="dialogVisible3" class="legalaid dialog_width500 dialog_special_class" :close-on-click-modal="false">
      <el-form label-width="100px" class="f2" :model="form1" id="f2">
        <el-form-item label="订单编号:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.orderNum"></el-input>
        </el-form-item>
        <el-form-item label="创建时间:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.createDate"></el-input>
        </el-form-item>
        <el-form-item label="订单状态:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.orderStateName"></el-input>
        </el-form-item>
        <el-form-item label="逾期天数:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.overdueDay" @input="codeInput1" maxlength="5"></el-input>
        </el-form-item>
        <el-form-item label="完成时间:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.finishDate"></el-input>
        </el-form-item>
        <el-form-item label="援助结果:" class="retained_profits retained_profits_special" v-show="this.dioasc4">
          <el-input type="textarea" placeholder="请输入内容" v-model="form1.collectionResult" maxlength="200" show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="援助结果:" class="retained_profits retained_profits_special" v-show="!this.dioasc4">
          <el-input type="textarea" placeholder="请输入内容" v-model="form1.collectionResult" maxlength="200" show-word-limit disabled>
          </el-input>
        </el-form-item>
        <el-form-item label="上传报告:" class="add_invoice_messages retained_profits el_upload_tip_class" v-show="uploadFormShow">
          <el-upload ref="uploadForm" action="fakeaction1" list-type="picture-card" v-model="file" :http-request="handleUploadFile1" class="upload-demo" accept=".excal,.xlsx,.docx,.xls">
            <div class="upload_box">
              <i class="el-icon-plus"></i>
            </div>
            <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
          </el-upload>
        </el-form-item>
        <div v-show="file&&uploadFormShow" class="invoiceUrlClass invoiceUrlClass_special" style="">
          <ul>
            <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
              <img :src="jpg + item">
              <div class="close_icon" @click="deleteUpload(index)">
                <i class="iconfont icon-yuyinguanbi"></i>
              </div>
            </li>
          </ul>
        </div>
        <el-form-item label="上传报告:" v-show="!uploadFormShow" class="reportUrl_class">
          <!-- <el-button v-show="!uploadFormShow" class="download_button flex_center" @click="loadingFile3()">
                <i class="iconfont icon-xiazai"></i>
                <span>下载报告</span>
            </el-button> -->
          <ul class="invimg_list flex_wrap">
            <li v-for=" (item,index) in files" :key="index" class="flex_center">
              <img :src="jpg + item">
            </li>
          </ul>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog-footer2" v-show="!this.dioasc3">
        <el-button class="footer_button2" @click="dialogVisible3=false">关 闭</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-show="this.dioasc3">
        <el-button class="footer_button2" round @click="dialogVisible3=false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="tijiao3()">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单详情" center :visible.sync="dialogVisible" id="wdrz" class="detail_width1000">
      <el-form label-width="100px" class="f2 f2_special_class">
        <el-row gutter="20">
          <el-col span="12">
            <div class="bg-purple-light">
              <el-form-item label="订单编号:" class="retained_profits form_item1">
                <el-input class="mc" disabled v-model="form1.orderNum"></el-input>
              </el-form-item>
              <el-form-item label="公司名称:" class="retained_profits form_item2">
                <el-input class="mc" disabled v-model="form1.subjectName"></el-input>
              </el-form-item>
              <el-form-item label="统一信用识别码:" class="retained_profits form_item2">
                <el-input class="mc" disabled v-model="form1.creditCode"></el-input>
              </el-form-item>
              <el-form-item label="详细地址:" class="retained_profits form_item2">
                <el-input class="mc" disabled v-model="form1.detailedAddress"></el-input>
              </el-form-item>
              <el-form-item label="是否授权:" class="retained_profits form_item2" v-if="form1.authorizedState!=1">
                <div class="are">是</div>
              </el-form-item>
              <el-form-item label="是否授权:" class="retained_profits form_item2" v-else-if="form1.authorizedState==1">
                <div class="are">否</div>
              </el-form-item>
              <el-form-item label="授权书:" class="retained_profits form_item2">
                <div class="upload-demo">
                  <ul class="invimg_list flex_wrap">
                    <li class="certification flex_center">
                      <img :src="jpg + form1.authorization">
                    </li>
                  </ul>
                </div>
              </el-form-item>
              <el-form-item label="联系人名称:" class="retained_profits form_item2">
                <el-input class="mc" disabled v-model="form1.cnName"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col span="12">
            <div class="bg-purple-light2">
              <el-form-item label="邮箱:" class="retained_profits form_item1">
                <el-input class="mc" disabled v-model="form1.email"></el-input>
              </el-form-item>
              <el-form-item label="联系方式:" class="retained_profits form_item2 ">
                <el-input class="mc" disabled v-model="form1.phone"></el-input>
              </el-form-item>
              <el-form-item label="完成时间:" class="retained_profits form_item2">
                <el-input class="mc" disabled v-model="form1.finishDate"></el-input>
              </el-form-item>
              <el-form-item label="上传证书:" class="retained_profits2 form_item2 form_pic" id="sczs" v-show="this.dioasc&&!form1.certificate">
                <el-upload action="fakeaction" list-type="picture" :http-request="handleUploadFile" ref="inputNumber3" v-model="form1.certificate" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
                  <div class="upload_box">
                    <i class="el-icon-plus"></i>
                  </div>
                  <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
                </el-upload>
              </el-form-item>

              <el-form-item label="上传证书:" class="retained_profits2 form_item2 form_pic" id="sczs" v-show="!this.dioasc||form1.certificate">
                <div class="upload-demo">
                  <ul class="invimg_list flex_wrap">
                    <li class="certification flex_center">
                      <img :src="jpg + form1.certificate">
                      <!-- <div class="close_icon" @click="deleteUpload()">
                        <i class="iconfont icon-yuyinguanbi"></i>
                      </div> -->
                    </li>
                  </ul>
                </div>
              </el-form-item>
              <el-form-item label="上传报告:" class="add_invoice_messages retained_profits2 form_item2 " id="sczs" v-show="uploadFormShow">
                <el-upload ref="uploadForm" action="fakeaction1" list-type="picture-card" v-model="file" :http-request="handleUploadFile1" class="upload-demo" accept=".excal,.xlsx,.docx,.xls">
                  <div class="upload_box">
                    <i class="el-icon-plus"></i>
                  </div>
                  <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
                </el-upload>
              </el-form-item>
              <div v-show="file&&uploadFormShow" class="invoiceUrlClass" style="">
                <ul>
                  <li v-for="(item,index) in file" :key="index" class="flex_center" style="position:relative">
                    <img :src="jpg + item">
                    <div class="close_icon" @click="deleteUpload(index)">
                      <i class="iconfont icon-yuyinguanbi"></i>
                    </div>
                  </li>
                </ul>
              </div>
              <el-form-item label="上传报告:" v-show="!uploadFormShow" class="reportUrl_class form_item2">
                <!-- <el-button v-show="!uploadFormShow" class="download_button flex_center" @click="loadingFile2()">
                <i class="iconfont icon-xiazai"></i>
                <span>下载报告</span>
            </el-button> -->
                <ul class="invimg_list flex_wrap">
                  <li v-for=" (item,index) in files" :key="index" class="flex_center">
                    <img :src="jpg + item">
                  </li>
                </ul>
              </el-form-item>
              <el-form-item label="上传图像压缩包:" class="retained_profits2 form_item2 form_pic2 " id="sczs" v-show="this.dioasc&&!form1.imagePackage">
                <el-upload action="fakeaction" list-type="picture" ref="inputNumber" :http-request="handleUploadFile2" v-model="form1.imagePackage" accept=".RAR,.ZIP">
                  <div class="upload_box">
                    <i class="el-icon-plus"></i>
                  </div>
                  <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
                </el-upload>
              </el-form-item>
              <el-form-item label="上传图像压缩包:" class="retained_profits2 form_item2 form_pic2" id="sczs" v-show="!this.dioasc||form1.imagePackage">
                <div class="upload-demo">
                  <ul class="invimg_list flex_wrap">
                    <li class="certification flex_center">
                      <img :src="jpg + form1.imagePackage">
                      <!-- <div class="close_icon" @click="deleteUpload2()">
                        <i class="iconfont icon-yuyinguanbi"></i>
                      </div> -->
                    </li>
                  </ul>
                </div>
              </el-form-item>
              <el-form-item label="是否认证:" id="rz" v-show="form1.authorizedState!=1&&this.dioasc" class="form_item2">
                <el-radio class="form_item2" v-model="form1.certificationState" label="1">认证完成</el-radio>
                <el-radio class="form_item3" v-model="form1.certificationState" label="0">认证失败</el-radio>
              </el-form-item>
              <el-form-item label="是否认证:" id="rz" v-show="form1.authorization==1||!this.dioasc" class="form_item2">
                <div>{{certificationState2}}</div>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer dialog-footer2" v-show="!this.dioasc">
        <el-button class="footer_button2" round @click="dialogVisible=false">关 闭</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-show="this.dioasc">
        <el-button class="footer_button2" round @click="dialogVisible=false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="tijiao()">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="dialogVisible4" class="legalaid102 dialog_width542 dialog_special_class" :close-on-click-modal="false">
      <el-form label-width="100px" class="f2" :model="form1" id="f2">
        <el-form-item label="订单编号:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.orderNum"></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.applicantCompany"></el-input>
        </el-form-item>
        <el-form-item label="订社会信用代码:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.creditCode"></el-input>
        </el-form-item>
        <el-form-item label="详细地址:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.detailedAddress"></el-input>
        </el-form-item>
        <el-form-item label="电话号码:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.companyTel"></el-input>
        </el-form-item>
        <el-form-item label="是否核实:" class="retained_profits2">
          <el-radio-group :disabled="check4" v-model="form1.certificationState">
            <el-radio label="1">已核实</el-radio>
            <el-radio label="0">未核实</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="订单状态:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.orderStateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog-footer2" v-show="!this.dioasc6">
        <el-button class="footer_button2" @click="dialogVisible4=false">关 闭</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-show="this.dioasc6">
        <el-button class="footer_button2" round @click="dialogVisible4=false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="tijiao4()">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="dialogVisible5" class="legalaid102 dialog_width542 dialog_special_class" :close-on-click-modal="false">
      <el-form label-width="100px" class="f2" :model="form1" id="f2">
        <el-form-item label="订单编号:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.orderNum"></el-input>
        </el-form-item>
        <el-form-item label="公司名称:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.applicantCompany"></el-input>
        </el-form-item>
        <el-form-item label="采购商名称:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.purchaseCompany"></el-input>
        </el-form-item>
        <el-form-item label="发票代码:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.invoiceNo"></el-input>
        </el-form-item>
        <el-form-item label="开票金额:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.invoiceAmount"></el-input>
        </el-form-item>
        <el-form-item label="开票日期:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.invoiceDate"></el-input>
        </el-form-item>
        <el-form-item label="是否核实:" class="retained_profits2">
          <el-radio-group :disabled="check5" v-model="form1.certificationState">
            <el-radio label="1">已核实</el-radio>
            <el-radio label="0">未核实</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="订单状态:" class="retained_profits2">
          <el-input class="mc" disabled v-model="form1.orderStateName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer dialog-footer2" v-show="!this.dioasc7">
        <el-button class="footer_button2" @click="dialogVisible5=false">关 闭</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-show="this.dioasc7">
        <el-button class="footer_button2" round @click="dialogVisible5=false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="tijiao5()">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Page from '@/components/page'
import { getOrderList, uploads, submitOrder, ExportOrderList, cancelOrder, getInvoiceList } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
import { downloadExport } from "@/api/api.js";
export default {
  mixins: [mixins1],
  components: { Page },
  data() {
    return {
      options: [{
        value: '1',
        label: '评级服务'
      }, {
        value: '2',
        label: '监踪服务'
      }, {
        value: '3',
        label: '法律服务'
      }, {
        value: '4',
        label: '认证服务'
      }, {
        value: '5',
        label: '注册认证服务'
      }, {
        value: '6',
        label: '发票认证服务'
      }
      ],
      options2: [{
        value: '1',
        label: '待办'
      }, {
        value: '2',
        label: '进行中'
      }, {
        value: '3',
        label: '已完成'
      }, {
        value: '4',
        label: '失败'
      }],
      arr3: true,
      arr2: true,
      arr: true,
      textarea: "",
      radio: '1',
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      tableData: [],
      id: "",
      certificationState2: "",
      form1: {
        authorization: "",
        authorizedState: "",
        certificate: "",
        certificationState: "",
        city: "",
        cnName: "",
        collectionResult: "",
        createDate: "",
        creditCode: "",
        detailedAddress: "",
        email: "",
        finishDate: "",
        flag: "",
        helpIdentity: "",
        helpReason: "",
        helpType: "",
        id: "",
        imagePackage: "",
        lastUpdateTime: "",
        orderNum: "",
        orderState: "",
        orderStateName: "",
        orderType: "",
        orderTypeName: "",
        overdueDay: "",
        phone: "",
        ratResult: "",
        ratType: "",
        receivableDate: "",
        report: "",
        subjectName: "",
        trackCycle: "",
        trackFrequency: "",
        underlyingAmount: "",
        userId: "",
        verificationMethod: "",
        verificationResult: "",
        verificationState: "",
      },
      pickerOptions: {
        // shortcuts: [{
        //   text: '最近一周',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        //     picker.$emit('pick', [start, end]);
        //   }
        // }, {
        //   text: '最近一个月',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        //     picker.$emit('pick', [start, end]);
        //   }
        // }, {
        //   text: '最近三个月',
        //   onClick(picker) {
        //     const end = new Date();
        //     const start = new Date();
        //     start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        //     picker.$emit('pick', [start, end]);
        //   }
        // }]
      },
      value1: '',
      value2: '',
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      form: {
        orderNum: "",
        orderType: "",
        orderState: "",
        createDate: "",
        finishDate: "",
        createDateE: "",
        finishDateE: "",
        createDateS: "",
        finishDateS: "",
        applicantCompany: "",
        subjectName: ""
      },
      size: "",
      childrenpage: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      onClickExpansionIndexkey: 1,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      paramsYears: '',
      file: [],
      uploadFormShow: true,
      orderNum: '',
      files: [],
      exceptArrivalClass: false,
      dataId: '',
      rowId1: '',
      rowId2: '',
      rowId3: '',
      fileName: [],
      filesNameS: [],
      dioasc: true,
      dioasc2: true,
      dioasc3: true,
      dioasc4: true,
      dioasc5: true,
      dioasc6: true,
      dioasc7: true,
      check5: false,
      check4: false,

    }
  },
  mounted() {
    this.getDate()
    this.resizeFn()
  },
  methods: {
    codeInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.form1.overdueDay = val
    },
    formInput1(val) {
      val = val.replace(/[^\d]/g, '')
      this.form1.trackCycle = val
    },
    formInput2(val) {
      val = val.replace(/[^\d]/g, '')
      this.form1.trackFrequency = val
    },
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.orderNum = val
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.width150 = 150
      } else {
        this.width150 = 110
      }
      this.$nextTick(function () {
        this.riskEchart1(this.fontSize, this.echartsWidth);
      });
    },
    exceptArrivalChange1() {
      if (this.form1.lastUpdateTime) {
        this.exceptArrivalClass = true
      }
      else {
        this.exceptArrivalClass = false
      }
    },
    deleteUpload() {
      this.form1.certificate = ''
    },
    deleteUpload1() {
      this.form1.report = ''
    },
    deleteUpload2() {
      this.form1.imagePackage = ''
    },
    selectionLineChangeHandle(val) {
      console.log(val);

    },
    async shujudaochu() {
      await ExportOrderList("createDateE=" + this.form.createDateE + "&createDateS=" + this.form.createDateS +
        "&finishDateE=" + this.form.finishDateE + "&finishDateS=" + this.form.finishDateS + "&orderNum=" + this.form.orderNum +
        "&orderState=" + this.form.orderState + "&orderType=" + this.form.orderType);
    },
    async chehui(row) {
      console.log(row);
      await cancelOrder("id=" + row.id)
      this.getDate()
    },
    searchForm() {
      this.page.pageNo = 1
      this.getDate()
    },
    async cleargetDate() {
      this.form.orderNum = ""
      this.form.orderType = ""
      this.form.orderState = ""
      this.form.createDate = ""
      this.form.finishDate = ""
      this.form.applicantCompany = ""
      if (this.form.createDate != null) {
        if (this.form.createDate[0]) {
          this.form.createDateE = this.form.createDate.slice(',')[0]
          this.form.createDateS = this.form.createDate.slice(',')[1]
        } else {
          this.form.createDateE = ""
          this.form.createDateS = ""
        }
      } else {
        this.form.createDateE = ""
        this.form.createDateS = ""
      }
      if (this.form.finishDate != null) {
        if (this.form.finishDate[0]) {
          this.form.finishDateE = this.form.finishDate.slice(',')[0]
          this.form.finishDateS = this.form.finishDate.slice(',')[1]
        } else {
          this.form.finishDateE = ""
          this.form.finishDateS = ""
        }
      } else {
        this.form.finishDateE = ""
        this.form.finishDateS = ""
      }
      this.page.pageNo = 1
      let date = await getOrderList("createDateS=" + this.form.createDateE + "&createDateE=" + this.form.createDateS +
        "&finishDateS=" + this.form.finishDateE + "&finishDateE=" + this.form.finishDateS + "&orderNum=" + this.form.orderNum +
        "&orderState=" + this.form.orderState + "&orderType=" + this.form.orderType + "&pageNo=" + 1 + "&pageSize="
        + this.page.pageSize + "&applicantCompany=" + this.form.applicantCompany + '&subjectName=' + this.form.subjectName);
      this.tableData = date.data.records
      this.page.total = date.data.total
      this.page.pageNo = date.data.current
      this.id = date.data.records.id
    },
    handleUploadFile(params) {
      console.log(params)
      console.log(this.form1.certificate);
      if (!this.form1.certificate) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          console.log(res);
          this.form1.certificate = res[0]
          console.log(this.form1.certificate);
        })
      }
    },
    handleUploadFile1(params) {
      if (!this.form1.report) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.file.push(res[0])
          this.fileName.push(params.file.name)
        })
      }
    },
    handleUploadFile2(params) {
      console.log(params)
      if (!this.form1.imagePackage) {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          console.log(res);
          this.form1.imagePackage = res[0]
        })
      }
    },
    async getOrder(item) {
      let res = await getOrderList('orderNum=' + item + '&orderType=' + '' + '&orderState=' + '' + '&createDateS=' + '' + '&createDateE=' + '' +
        '&finishDateS=' + '' + '&finishDateE=' + '')
      console.log(res.data.records)
      res.data.records.forEach((item, index) => {
        console.log(item)
        this.files = item.reportUrl
        this.filesNameS = item.reportNames
      })
      if (this.files) {
        this.uploadFormShow = false
      } else {
        this.uploadFormShow = true
      }
      this.getDate()
    },
    async tijiao() {
      if (this.file.length > 0) {
        this.form1.reportUrl = this.file
        this.form1.reportNames = this.fileName
      } else {
        this.form1.reportUrl = this.files
        this.form1.reportNames = this.filesNameS
      }
      await submitOrder(this.form1)
      if (this.form1.imagePackage && this.form1.report && this.form1.certificate && this.form1.authorizedState) {
        this.dioasc = false
      }
      this.dialogVisible = false

      this.getDate()
    },
    async tijiao2() {
      // this.form1.reportUrl = this.file
      // this.form1.reportNames = this.fileName
      if (this.file.length > 0) {
        this.form1.reportUrl = this.file
        this.form1.reportNames = this.fileName
      } else {
        this.form1.reportUrl = this.files
        this.form1.reportNames = this.filesNameS
      }
      console.log(this.form1.lastUpdateTime);
      await submitOrder(this.form1)
      if (this.form1.report && this.form1.lastUpdateTime) {
        this.dioasc2 = false
      }
      // if(this.form1.orderState==3){
      //   this.dioasc2=false
      // }
      this.dialogVisible2 = false
      this.getDate()
    },
    async tijiao3() {
      console.log(this.file.length)
      if (this.file.length > 0) {
        this.form1.reportUrl = this.file
        this.form1.reportNames = this.fileName
      } else {
        this.form1.reportUrl = this.files
        this.form1.reportNames = this.filesNameS
      }
      // console.log(this.files)
      // this.form1.reportUrl = this.file
      // this.form1.reportNames = this.fileName
      await submitOrder(this.form1)
      if (this.form1.collectionResult && this.file.length > 0) {
        this.dioasc3 = false
      }
      // if(this.form1.orderState==3){
      //   this.dioasc3=false
      // }else{
      //   this.dioasc3=true
      // }
      this.dialogVisible3 = false
      this.getDate()
      // console.log(this.dioasc3);
      // this.$router.go(0)
    },
    async tijiao4() {

      // this.form1.reportUrl = this.file
      // this.form1.reportNames = this.fileName
      await submitOrder(this.form1)
      // if (this.form1.collectionRsult && this.file.length > 0) {
      //   this.dioasc6 = false
      // }
      console.log(this.form1.orderState)
      if (this.form1.orderState == 3) {
        this.dioasc6 = false
        this.check4 = true//控制核实radio是否可以编写
      } else {
        console.log(this.form1.orderState)
        this.dioasc6 = true
        this.check4 = false//控制核实radio是否可以编写
      }
      this.dialogVisible4 = false
      this.getDate()
      // console.log(this.dioasc3);
      // this.$router.go(0)
    },
    async tijiao5() {
      // this.form1.reportUrl = this.file
      // this.form1.reportNames = this.fileName
      await submitOrder(this.form1)
      // if (this.form1.collectionRsult && this.file.length > 0) {
      //   this.dioasc7 = false
      // }
      if (this.form1.orderState == 3) {
        this.dioasc7 = false
        this.check4 = true//控制核实radio是否可以编写
      } else {
        this.dioasc7 = true
        this.check4 = false//控制核实radio是否可以编写
      }
      this.dialogVisible5 = false
      this.getDate()
      // console.log(this.dioasc3);
      // this.$router.go(0)
    },
    async getDate() {
      if (this.form.createDate != null) {
        if (this.form.createDate[0]) {
          this.form.createDateE = this.form.createDate.slice(',')[0]
          this.form.createDateS = this.form.createDate.slice(',')[1]
        } else {
          this.form.createDateE = ""
          this.form.createDateS = ""
        }
      } else {
        this.form.createDateE = ""
        this.form.createDateS = ""
      }
      if (this.form.finishDate != null) {
        if (this.form.finishDate[0]) {
          this.form.finishDateE = this.form.finishDate.slice(',')[0]
          this.form.finishDateS = this.form.finishDate.slice(',')[1]
        } else {
          this.form.finishDateE = ""
          this.form.finishDateS = ""
        }
      } else {
        this.form.finishDateE = ""
        this.form.finishDateS = ""
      }
      // this.page.pageNo = 1
      let date = await getOrderList("createDateS=" + this.form.createDateE + "&createDateE=" + this.form.createDateS +
        "&finishDateS=" + this.form.finishDateE + "&finishDateE=" + this.form.finishDateS + "&orderNum=" + this.form.orderNum +
        "&orderState=" + this.form.orderState + "&orderType=" + this.form.orderType + "&pageNo=" + this.page.pageNo + "&pageSize=" + this.page.pageSize + "&applicantCompany=" + this.form.applicantCompany + '&subjectName=' + this.form.subjectName);
      this.tableData = date.data.records
      this.page.total = date.data.total
      this.id = date.data.records.id
    },
    a(row) {
      this.getOrder(row.orderNum)
      this.form1 = row
      this.orderNum = row.orderNum
      this.paramsYears = row.year
      this.dataId = row.id
      if (row.orderType == 4) {
        this.rowId2 = row.id
        this.dialogVisible = true;
        if (row.orderState == 3) {
          if (this.form1.report && this.form1.certificate && this.form1.imagePackage && this.form1.authorizedState && this.form1.certificationState) {
            this.dioasc = false
          }

          if (this.form1.certificationState == 1) {
            this.certificationState2 = "是"
          } else if (this.form1.certificationState == 0) {
            this.certificationState2 = "否"
          }
        } else if (row.orderState == 2) {
          if (this.form1.report && this.form1.certificate && this.form1.imagePackage && this.form1.authorizedState && this.form1.certificationState) {
            this.dioasc = false
          }
          if (this.form1.certificationState == 1) {
            this.certificationState2 = "是"
          } else if (this.form1.certificationState == 0) {
            this.certificationState2 = "否"
          }

        } else {
          this.dioasc = true
        }
      }
      else if (row.orderType == 2) {
        this.rowId1 = row.id
        if (row.orderState == 2) {
          if (row.reportUrl && row.lastUpdateTime) {
            this.dioasc2 = false
            this.dioasc5 = false
          }
          else if (!row.reportUrl && row.lastUpdateTime) {
            this.dioasc2 = true
            this.dioasc5 = false
          }
          else if (row.reportUrl && !row.lastUpdateTime) {
            this.dioasc2 = true
            this.dioasc5 = true
          }
          else {
            this.dioasc2 = true
            this.dioasc5 = true
          }
        }
        else if (row.orderState == 3) {
          if (row.reportUrl && row.lastUpdateTime) {
            this.dioasc2 = false
            this.dioasc5 = false
          }
          else {
            this.dioasc2 = true
            this.dioasc5 = true
          }

        } else {
          this.dioasc2 = true
        }
        this.dialogVisible2 = true;
      }
      else if (row.orderType == 3) {
        this.rowId3 = row.id
        this.dialogVisible3 = true;
        if (row.orderState == 3) {
          if (this.form1.collectionResult && row.reportUrl) {
            this.dioasc3 = false
            this.dioasc4 = false
          } else {
            this.dioasc3 = true
            this.dioasc4 = true
          }
        } else if (row.orderState == 2) {
          if (this.form1.collectionResult && row.reportUrl) {
            this.dioasc3 = false
            this.dioasc4 = false
          }
          else if (this.form1.collectionResult && !row.reportUrl) {
            this.dioasc3 = true
            this.dioasc4 = false
          }
          else if (!this.form1.collectionResult && row.reportUrl) {
            this.dioasc3 = true
            this.dioasc4 = true
          }
          else {
            this.dioasc3 = true
            this.dioasc4 = true
          }
        } else {
          this.dioasc3 = true
        }
      }
      else if (row.orderType == 1) {
        let data = JSON.stringify(row)
        localStorage.setItem("row", data)
        this.$router.push({
          path: 'ratingsServices',
          name: 'RatingsServices',
          query: {
            years: this.paramsYears,
            id: this.dataId
          }
        })
      } else if (row.orderType == 5) {
        console.log(this.form1.orderState)
        if (this.form1.orderState == 3) {
          this.check4 = true//控制核实radio是否可以编写
          this.dioasc6 = false
        } else {
          this.check4 = false//控制核实radio是否可以编写
          this.dioasc6 = true
        }
        this.dialogVisible4 = true
      } else if (row.orderType == 6) {
        if (this.form1.orderState == 3) {
          this.check5 = true//控制核实radio是否可以编写
          this.dioasc7 = false
        } else {
          this.check5 = false//控制核实radio是否可以编写
          this.dioasc7 = true
        }
        this.dialogVisible5 = true
      }
      this.file = []
    },
    // 监踪服务下载报告
    async loadingFile(row) {
      let res = await downloadExport('id=' + row.id)
      res.data.url.forEach((item, index) => {
        var link = document.createElement("a");
        link.setAttribute("download", res.data.urlName[index]);
        // link.href = item;
        link.href = this.jpg + item;
        link.click()
      })
    },
    // // 认证服务下载报告
    // async loadingFile2(){
    //   let res = await downloadExport('id='+this.rowId2)
    //   res.data.url.forEach((item,index) => {
    //       var link = document.createElement("a");
    //       link.setAttribute("download", res.data.urlName[index]);
    //       link.href = item;
    //       link.click()
    //   })
    // },
    // // 法律服务下载报告
    // async loadingFile3(){
    //   let res = await downloadExport('id='+this.rowId3)
    //   res.data.url.forEach((item,index) => {
    //       var link = document.createElement("a");
    //       link.setAttribute("download", res.data.urlName[index]);
    //       link.href = item;
    //       link.click()
    //   })
    // },
  }
}
</script>

<style  scoped>
  ::v-deep.dialog_width542 .el-dialog {
    width: 542px;
  }
  ::v-deep.dialog_width542 .el-dialog__footer {
    text-align: left;
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }

  .particulars_class {
    color: #e1e7ea;
  }
  .particulars {
    color: #339c9b;
  }
  ::v-deep.detail_width1000 .el-dialog {
    width: 1030px;
    height: auto;
  }
  ::v-deep.detail_width1000 .retained_profits {
    display: flex;
  }
  ::v-deep.detail_width1000 .el-form-item::after {
    margin-left: 20px;
    /* margin-right: 00px; */
    /* padding-top:10px; */
    /* margin-top: 10px; */
  }
  ::v-deep.detail_width1000 .el-dialog--center .el-dialog__footer {
    margin-top: -28px;
    text-align: right;
  }
  ::v-deep.detail_width1000 .el-dialog--center .el-dialog__body {
    max-height: none;
  }
  .bg-purple-light {
    border-radius: 10px;
    margin-left: -10px;
    padding-left: -10px;
    width: 480px;
    height: 530px;
    background: #f7fafc;
  }
  .bg-purple-light2 {
    border-radius: 10px;
    /* margin-left: 00px; */
    width: 480px;
    height: auto;
    background: #f7fafc;
    padding-bottom: 17px;
  }
  .form_item1 {
    margin-left: 40px;
    margin-right: 20px;
    padding-top: 20px;
    /* padding-left: 20px; */
  }
  ::v-deep.form_item1 .el-form-item__label {
    margin-left: 20px;
  }
  ::v-deep.form_item2 .el-form-item__label {
    margin-left: 0px;
  }
  ::v-deep.form_item2 .el-form-item__content {
    display: flex;
    margin-left: -40px;
  }
  /* ::v-deep .form_item2 .flex_wrap{
                                                                                                                                                                                                                                                                                                                                               margin-left: -40px;
                                                                                                                                                                                                                                                                                                                                            } */
  ::v-deep .form_item2.form_item3 .el-radio {
    margin-left: -40px;
  }
  ::v-deep .form_item2 .el-radio__label {
    padding-top: 4px;
  }
  ::v-deep .form_item2 {
    left: -54px;
    display: flex;
  }

  .form_item2 >>> .el-cascader-node > .el-radio,
  .el-radio:last-child {
    left: -18px;
  }
  /* ::v-deep .dialog_special_class .el-form-item__label{
                                                                                                                                                                                                                                                                                                                                              width: 72px !important; 
                                                                                                                                                                                                                                                                                                                                            } */
  ::v-deep .el_upload_tip_class .el-upload__tip {
    right: 99px !important;
  }
  .invoiceUrlClass_special ul {
    margin-top: -20px;
    margin-left: 70px !important;
  }
  ::v-deep .dialog_special_class .el-dialog__body {
    padding-top: 30px !important;
    padding-bottom: 0 !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  /* 下载报告 */
  .download_button {
    /* position: absolute;
                                                                                                                                                                                                                                                                                                                                                right: -260px;
                                                                                                                                                                                                                                                                                                                                                top: 0; */
    width: 120px;
    height: 30px;
    margin-bottom: 20px;
    /* margin-top: -20px; */
    color: #339c9b;
    background: #ffffff;
    border-radius: 32px;
    border: 1px solid #e1e7ea;
  }
  .download_button.el-button--default:hover,
  .download_button.el-button--default:focus {
    color: #339c9b;
  }
  .download_button span {
    font-size: 16px;
  }
  .download_button i {
    margin-right: 6px;
    font-size: 18px;
  }
  .activit >>> .el-form-item {
    float: left;
    margin-bottom: 20px !important;
    margin-right: 60px !important;
  }
  ::v-deep .el-textarea.is-disabled .el-textarea__inner {
    color: #7c969b;
  }
  ::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep
    .exceptArrivalClass
    .is-success
    .el-date-editor--date:hover
    .el-input__prefix {
    display: none;
  }
  #jzfw2 >>> .el-dialog__body {
    padding-top: 30px !important;
    padding-bottom: 0px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  #sczs.add_invoice_messages .el-form-item {
    margin-bottom: 2px !important;
  }
  .add_invoice_messages >>> .el-upload-list {
    display: none;
  }
  .f2_special_class .invoiceUrlClass ul {
    margin-left: 138px;
    margin-top: -30px;
  }
  .invoiceUrlClass li {
    float: left;
    width: 60px;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 30px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .invoiceUrlClass li:not(:last-child) {
    margin-bottom: 0;
  }

  .invoiceUrlClass ul {
    display: inline-block;
    margin-left: 112px;
    /* margin-top: 70px; */
  }
  .reportUrl_class li {
    float: left;
    width: 60px;
    height: 60px;
    /* margin-top: 30px; */
    margin-bottom: 10px;
    margin-right: 30px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .reportUrl_class ul {
    display: inline-block;
    margin-left: -52px;
    /* margin-top: 70px; */
  }
  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
  }
  .close_icon i {
    position: relative;
    top: -2px;
    left: 2px;
    font-size: 8px;
    color: #ffffff;
  }
  .search_boxs {
    padding-top: 23px;
  }
  .limit_width {
    width: 1500px;
    height: auto;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .system_footer {
    min-height: calc(100vh - 60px);
  }
  .panel {
    width: 1500px;
    background: #ffffff;
    border-radius: 14px;
  }
  ::v-deep .target_name_class .el-form-item__label {
    width: 100px !important;
  }
  ::v-deep .target_name_class .el-input__inner {
    width: 272px !important;
  }
  .service_nav {
    width: 1460px;
    height: 170px;
    padding-top: 20px;
    padding-left: 20px;
    background: #edf2f5;
    border-radius: 10px;
  }
  .service_main {
    width: 1460px;
    background: #edf2f5;
    border-radius: 10px;
    margin-top: 20px;
  }
  .anniudk {
    float: left;
    /* margin-top: 22px; */
    width: 500px;
    /* margin-bottom: 40px; */
    padding: 22px 70px 22px 70px;
    display: flex;
  }
  .anniu2 {
    float: left;
    margin-left: 70px;
    margin-top: 30px;
  }
  >>> .dialog_width500 .el-form-item__label {
    min-width: 0px;
  }
  >>> .dialog_width500 .el-dialog__footer {
    text-align: left;
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }

  >>> #jzfw2 .el-form-item__label {
    min-width: 100px;
  }

  .are {
    margin-left: -52px;
    /* width: 300px !important; */
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 24px;
    padding: 0 !important;
  }
  .service_main .orderscentre {
    width: 72px;
    height: 22px;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 40px;
    margin-left: 20px;
    margin-top: 30px;
    float: left;
  }
  .service_main_header {
    float: left;
    margin-bottom: 20px;
  }
  .service_main .anniu {
    width: 140px;
    height: 36px;
    /* background: #339C9B; */
    background: #7c969b;
    border-radius: 25px;
    line-height: 0px;
    margin-left: 1208px;
    margin-top: 30px;
    color: #ffffff;
    border-color: #339c9b;
  }
  .anniu span {
    width: 72px;
    height: 20px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 40px;
  }
  .activity {
    float: left;
    margin: 20px 50px 20px 20px;
  }
  .activity2 {
    float: left;
    margin: 0px 50px 20px 20px;
  }
  >>> .el-upload__tip {
    position: absolute;
    top: 30px;
    right: 54px;
  }
  >>> .f2 .el-form-item__label {
    width: 88px !important;
    height: 16px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 12px;
    margin-right: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px;
  }
  >>> #wdrz .f2 .el-form-item__label {
    width: 142px !important;
    height: 16px;
    /* border: 1px solid; */
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 12px;
    margin-right: 26px;
    margin-left: 25px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0px;
  }
  .search {
    width: 100px;
    float: left;
    margin-top: 40px;
  }
  .search_div {
    width: 40px;
    height: 40px;
    float: left;
    border-radius: 50%;
    background: #339c9b;
  }
  .activit {
    float: left;
    width: 1329px;
  }
  >>> .el-dialog__footer {
    text-align: left;
    margin-left: 50px;
  }
  .search i {
    line-height: 40px;
    font-size: 20px;
    color: #ffffff;
    margin-left: 10px;
  }
  .search_div2 {
    width: 40px;
    height: 40px;
    float: left;
    border-radius: 50%;
    background: #7c969b;
    margin-left: 20px;
  }
  .retained_profits3 .upload-demo {
    padding: 0px;
    float: left;
    width: 328px;
  }
  .upload-demo {
    padding: 0px;
    float: left;
    width: 328px;
  }
  .wz {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #92a2bc;
    line-height: 16px;
    margin-left: 20px;
    float: left;
    width: 283px;
  }
  >>> .anniu2 .qiuxiao {
    width: 160px;
    height: 40px;
    background: #7c969b;
    border-radius: 32px;
  }
  .qiuxiao {
    width: 160px;
    height: 40px;
    background: #7c969b;
    border-radius: 32px;
    float: left;
  }
  .tijiaok {
    /* float: left; */
    margin-left: 40px;
  }
  >>> .el-input__prefix {
    left: 90%;
  }
  .tijiao {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-color: #339c9b;
    border-radius: 32px;
  }
  >>> .anniu2 .tijiao {
    width: 160px;
    height: 40px;
    background: #339c9b;
    border-radius: 32px;
  }
  >>> .el-input__inner {
    width: 300px;
    height: 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  >>> .activity2 .el-input__inner {
    width: 300px;
    height: 30px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  ::v-deep .el-form-item {
    margin: 0 !important;
    /* float: left; */
  }
  .el-form-item >>> .el-date-editor .el-range__icon {
    position: absolute;
    left: 50%;
    top: -1px;
    transform: translateX(-50%);
  }
  >>> .el-form-item__label {
    width: 72px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  >>> .el-radio {
    margin-top: 8px;
  }
  >>> .el-form-item__content {
    float: left;
    margin: 0 !important;
  }
  /* ::v-deep .el-form-item__content{
                                                                                                                                                                                                                                                                                                                                                  float: left;
                                                                                                                                                                                                                                                                                                                                                 width: 300px;
                                                                                                                                                                                                                                                                                                                                            } */
  ::v-deep .search_condition .el-input {
    width: 300px;
    height: 30px;
  }
  >>> #sczs .el-form-item__content > div {
    margin-left: -16px;
    width: 323px;
    float: left;
  }
  >>> .el-table {
    margin: 20px 20px 14px 20px;
    width: 1420px !important;
  }
  >>> .f2 .mc .el-input {
    width: 328px;
    height: 40px;
  }
  >>> .el-table th > .cell {
    text-align: center;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
  }
  >>> .el-table th {
    padding: 0px;
  }
  .tishi {
    font-size: 12px;
    margin-top: 30px;
    display: inline-block;
    color: #92a2bc;
  }
  >>> .el-upload-list--picture-card .el-upload-list__item {
    height: 60px;
    width: 60px;
  }
  >>> .el-table .cell {
    text-align: center;
    height: 50px;
    line-height: 50px;
  }
  >>> .has-gutter .cell {
    text-align: center;
    height: 60px;
    line-height: 60px;
  }
  >>> .el-pagination__editor.el-input .el-input__inner {
    width: 54px;
    height: 27px !important;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(12, 79, 71, 0.16);
    border-radius: 20px;
  }
  >>> .el-pagination {
    margin-right: 20px;
  }
  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }
  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  >>> .el-input__icon.el-icon-date {
    left: 45%;
    position: absolute;
    top: -2px;
  }
  >>> .el-date-editor .el-range-separator {
    display: none;
  }
  /* >>>.iconfont.el-tooltip:nth-child(n){
                                                                                                                                                                                                                                                                                                                                              color: #339C9B;
                                                                                                                                                                                                                                                                                                                                              height: 20px !important;
                                                                                                                                                                                                                                                                                                                                              font-size: 20px;
                                                                                                                                                                                                                                                                                                                                            } */
  >>> .certification .el-dialog {
    width: 500px !important;
    height: 1086px !important;
    background: #ffffff;
    box-shadow: 20px 40px 70px 0px rgba(12, 79, 71, 0.08);
    border-radius: 10px;
  }
  >>> .el-dialog__header {
    text-align: center;
    font-size: 18px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 24px;
    padding-bottom: 00px;
    padding-top: 30px;
  }
  >>> .certification .el-dialog__body {
    padding: 0;
  }
  >>> .retained_profits {
    margin-top: 10px;
    margin-bottom: 20px !important;
  }

  >>> .retained_profits2 {
    margin-bottom: 20px !important;
  }
  >>> .retained_profits3 {
    margin-bottom: 2px !important;
  }

  >>> .certification .retained_profits4 {
    margin-top: 8px !important;
  }
  >>> .certification .retained_profits2 .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 !important;
    margin-right: 10px;
  }
  >>> .certification #sczs .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 8px;
    margin-bottom: 28px;
    padding: 0 !important;
    margin-right: 10px;
  }
  >>> .certification .retained_profits .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 !important;
    margin-right: 10px;
  }
  >>> .certification .retained_profits5 .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 !important;
    margin-right: 10px;
  }
  >>> .certification .retained_profits3 .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 38px;
    margin-bottom: 38px;
    padding: 0 !important;
    margin-right: 10px;
  }
  >>> .certification .retained_profits4 .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    padding: 0 !important;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  >>> .certification .mc .el-input__inner {
    width: 328px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  >>> .certification .el-upload-list {
    margin-top: 0px;
    /* float: left; */
  }
  >>> .el-upload-list__item .el-icon-close {
    /* display: none; */
  }
  >>> .certification .el-upload-list li {
    float: left;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    background: #f7fafc;
    margin: 0;
  }
  >>> .certification .el-upload-list__item-name [class^='el-icon'] {
    width: 26px;
    height: 30px;
  }
  >>> .certification .retained_profits3 .el-upload {
    display: none;
  }
  >>> .retained_profits .el-upload--picture-card {
    width: 60px;
    height: 60px;
  }

  >>> #sczs .el-upload--picture-card {
    width: 60px;
    height: 60px;
    margin: 0;
    border: 1px solid #f7fafc;
    background-color: #f7fafc;
    border-radius: 4px;
  }
  >>> #sczs .el-upload--picture-card i {
    font-size: 20px;
    color: #7c969b;
    position: relative;
    bottom: 42px;
    text-align: center;
  }
  >>> .retained_profits .el-upload--picture-card i {
    bottom: 44px;
  }
  >>> #rz .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 19px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 0 !important;
    margin-right: 10px;
  }

  /* 监踪服务 */
  >>> .monitortrace .el-dialog {
    width: 500px !important;
    height: 614px;
    background: #ffffff;
    box-shadow: 20px 40px 70px 0px rgb(12 79 71 / 8%);
    border-radius: 10px;
  }
  >>> .monitortrace .el-form-item__label {
    width: 88px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 28px;
    padding: 0;
    margin-right: 10px;
  }
  >>> #scbg .el-form-item__content > div {
    float: left;
  }
  >>> .monitortrace .el-input__icon.el-icon-date {
    transform: scale(1);
    margin-left: 312px;
  }
  >>> .monitortrace .el-input__suffix {
    display: none;
  }
  >>> .monitortrace .el-dialog__body {
    padding: 0px;
  }

  >>> .monitortrace .el-input__inner {
    width: 342px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  >>> .monitortrace .el-textarea {
    border: 1px solid red;
    width: 370px;
    height: 80px;
  }
  >>> .monitortrace .el-dialog .el-textarea__inner {
    width: 370px;
    height: 80px;
    border-radius: 4px;
  }

  /* 法律援助*/
  >>> .legalaid .el-form-item__label {
    width: 71px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 28px;
    padding: 0;
    margin-right: 10px;
  }
  >>> .legalaid102 .el-form-item__label {
    width: 102px !important;
    height: 24px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 28px;
    padding: 0;
    margin-right: 10px;
  }
  /* >>>.legalaid .el-dialog{
                                                                                                                                                                                                                                                                                                                                              width: 500px !important;
                                                                                                                                                                                                                                                                                                                                              height: 614px;
                                                                                                                                                                                                                                                                                                                                              background: #FFFFFF;
                                                                                                                                                                                                                                                                                                                                              box-shadow: 20px 40px 70px 0px rgb(12 79 71 / 8%);
                                                                                                                                                                                                                                                                                                                                              border-radius: 10px;
                                                                                                                                                                                                                                                                                                                                            } */
  >>> .legalaid .el-input__icon.el-icon-date {
    top: 2px;
    transform: scale(1.6);
    margin-left: 312px;
  }
  >>> .legalaid .el-input__suffix {
    display: none;
  }
  /* >>>.legalaid .el-dialog__body{
                                                                                                                                                                                                                                                                                                                                              padding: 0px;
                                                                                                                                                                                                                                                                                                                                            } */

  >>> .legalaid .el-input__inner {
    width: 370px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  >>> .legalaid .el-textarea {
    width: 370px;
    height: 80px;
  }
  >>> .legalaid .el-dialog .el-textarea__inner {
    width: 370px;
    height: 80px;
    border-radius: 4px;
  }
  >>> #rz .certification .retained_profits .el-form-item__label {
    width: 87px !important;
    height: 16px;
    font-size: 9px;
    font-family: MicrosoftYaHei;
    color: #162747;
    line-height: 12px;
    margin-top: 5px;
    padding: 0 !important;
    margin-right: 6px;
    border: 1px solid red;
  }
  >>> .totalFont {
    margin-left: 20px;
  }
  >>> .el-table--enable-row-transition .el-table__body td {
    padding: 0px;
  }
  >>> .el-dialog__body {
    padding: 30px !important;
  }
  >>> .f2 .el-input__inner {
    width: 332px;
    height: 40px;
  }
  >>> .f2 .el-input.is-disabled .el-input__inner {
    width: 332px;
    height: 40px;
  }
  >>> #f2 .el-input.is-disabled .el-input__inner {
    width: 370px;
    height: 40px;
  }
  >>> #wdrz .f2 .el-input.is-disabled .el-input__inner {
    margin-left: -16px;
    width: 323px;
    height: 40px;
  }
  /* >>>.el-input__prefix{
                                                                                                                                                                                                                                                                                                                                                  left: 145%;
                                                                                                                                                                                                                                                                                                                                                } */
  >>> .upload-demo .certification {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    margin-left: 4px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  /* >>> .dialog-footer2 {
                                                                                                                              margin-left: 100px;
                                                                                                                            } */
  >>> .el-dialog__footer .dialog-footer2 .el-button--default {
    background: #339c9b;
  }
  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
  }
  .close_icon i {
    position: relative;
    top: -3px;
    left: 2px;
    font-size: 8px;
    color: #ffffff;
  }
  .pageBox {
    padding: 0 0 19px 0;
  }
  @media screen and (max-width: 1664px) {
    ::v-deep.detail_width1000 .el-dialog {
      width: 820px;
      height: auto;
    }
    ::v-deep.detail_width1000 .retained_profits {
      display: flex;
    }
    ::v-deep.detail_width1000 .el-form-item::after {
      margin-left: 5px;
      /* margin-right: 00px; */
      /* padding-top:10px; */
      /* margin-top: 10px; */
    }
    ::v-deep.detail_width1000 .el-dialog--center .el-dialog__footer {
      margin-top: 10px;
      text-align: right;
    }
    .bg-purple-light {
      border-radius: 10px;
      margin-left: 1px;
      padding-right: 10px;
      width: 381px;
      height: 370px;
      background: #f7fafc;
    }
    .bg-purple-light2 {
      padding-right: 11px;
      border-radius: 10px;
      margin-left: -2px;
      width: 385px;
      height: auto;
      background: #f7fafc;
    }
    .form_item1 {
      margin-left: 40px;
      margin-right: 20px;
      padding-top: 20px;
      /* padding-left: 20px; */
    }
    .form_pic >>> .upload-demo .certification {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      margin-left: 0px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .form_pic2 >>> .upload-demo .certification {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      margin-left: 0px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    ::v-deep.form_item1 .el-form-item__label {
      margin-left: 20px;
    }
    ::v-deep.form_item1 .el-input__inner {
      margin-left: -40px;
    }
    ::v-deep.form_item2 .el-form-item__label {
      margin-left: 20px;
    }
    ::v-deep.form_item2 .el-input__inner {
      margin-left: -40px;
    }
    ::v-deep.form_item2 .el-form-item__content {
      display: flex;
      /* margin-left: -20px; */
    }
    ::v-deep .form_item2 >>> #wdrz .el-form-item__content > div {
      margin-left: -10px;
    }
    /* ::v-deep .form_item2 .el-radio{
                                                                                                                                                                                                                                                                                                                                             margin-left: -40px
                                                                                                                                                                                                                                                                                                                                            } */
    ::v-deep .form_item2.form_item3 .el-radio {
      margin-left: -40px;
    }
    ::v-deep .form_item2 {
      left: -38px;
      display: flex;
    }

    ::v-deep .el_upload_tip_class .el-upload__tip {
      right: 12px !important;
    }
    .invoiceUrlClass_special ul {
      margin-top: -20px;
      margin-left: 60px !important;
    }
    ::v-deep.retained_profits_special.el-form-item {
      margin-bottom: 26px !important;
    }
    ::v-deep .dialog_special_class .el-dialog__body {
      padding-top: 20px !important;
      padding-bottom: 0 !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    /* 下载报告 */
    .download_button {
      /* position: absolute;
                                                                                                                                                                                                                                                                                                                                                    right: -170px; */
      width: 80px;
      height: 20px;
      margin-bottom: 13px;
      /* margin-top: -13px; */
      color: #339c9b;
      background: #ffffff;
      border-radius: 22px;
      border: 1px solid #e1e7ea;
    }
    .download_button span {
      font-size: 12px;
    }
    .download_button i {
      margin-right: 4px;
      font-size: 12px;
    }
    .f2_special_class .invoiceUrlClass ul {
      margin-left: 121px;
      margin-top: -20px;
    }
    .activit >>> .el-form-item {
      float: left;
      margin-right: 30px !important;
      margin-bottom: 13px !important;
    }
    ::v-deep .el-input__prefix {
      left: 88%;
    }
    ::v-deep .el-input--prefix .el-input__inner {
      padding-left: 10px;
    }
    #jzfw2 >>> .el-dialog__body {
      padding-top: 20px !important;
      padding-bottom: 0px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    /* ::v-deep .add_invoice_messages .el-form-item{
                                                                                                                                                                                                                                                                                                                                              margin-bottom: 2px !important;
                                                                                                                                                                                                                                                                                                                                            } */
    .invoiceUrlClass li {
      float: left;
      width: 40px;
      height: 40px;
      margin-top: 20px;
      margin-bottom: 6px;
      margin-right: 20px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .invoiceUrlClass ul {
      display: inline-block;
      margin-left: 93px;
      /* margin-top: 60px; */
    }
    .reportUrl_class li {
      float: left;
      width: 40px;
      height: 40px;
      /* margin-top: 20px; */
      margin-bottom: 6px;
      margin-right: 20px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .reportUrl_class ul {
      display: inline-block;
      margin-left: -40px;
      /* margin-top: 60px; */
    }
    .invoiceUrlClass li {
      float: left;
      width: 40px;
      height: 40px;
      /* margin-top: 20px; */
      margin-bottom: 6px;
      margin-right: 20px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .invoiceUrlClass ul {
      display: inline-block;
      margin-left: 95px;
      /* margin-top: 60px; */
    }
    .close_icon {
      position: absolute;
      top: -6px;
      left: 32px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.8);
    }
    .close_icon i {
      position: relative;
      top: -2px;
      left: 2px;
      font-size: 8px;
      color: #ffffff;
    }
    >>> #jzfw2 .el-form-item__label {
      min-width: 85px;
    }
    .pageBox {
      padding: 0 0 12px 0;
    }
    .close_icon {
      position: absolute;
      top: -6px;
      left: 32px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.8);
    }
    .close_icon i {
      position: relative;
      top: -3px;
      left: 2px;
      font-size: 8px;
      color: #ffffff;
    }
    >>> .dialog-footer2 .el-dialog__footer .el-button--default {
      background: #339c9b;
    }
    >>> #f2 .el-input.is-disabled .el-input__inner {
      width: 236px;
      height: 26px;
    }
    /* >>> .dialog-footer2 {
                                                                                                                                margin-left: 66px;
                                                                                                                              } */
    >>> .upload-demo .certification {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      margin-left: -45px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .search_boxs {
      padding-top: 14px;
    }
    >>> .el-dialog__body {
      padding: 20px !important;
    }
    >>> .f2 .el-input__inner {
      width: 202px;
      height: 26px;
    }
    >>> #zhgxsj .el-input__inner {
      width: 208px;
      height: 26px;
    }
    .limit_width {
      width: 1000px;
      height: auto;
      margin: 0 auto;
      padding-top: 66px;
      padding-bottom: 26px;
    }
    .system_footer {
      min-height: calc(100vh - 40px);
    }
    >>> .totalFont {
      margin-left: 13px;
    }
    .panel {
      width: 1000px;
      background: #ffffff;
      border-radius: 9px;
    }
    ::v-deep .target_name_class .el-form-item__label {
      width: 87px !important;
    }
    ::v-deep .target_name_class .el-input__inner {
      width: 177px !important;
    }
    .service_nav {
      width: 973px;
      height: 113px;
      padding-top: 13px;
      padding-left: 13px;
      background: #edf2f5;
      border-radius: 6px;
    }
    .service_main {
      width: 973px;
      background: #edf2f5;
      border-radius: 6px;
      margin-top: 13px;
    }
    .anniu2 {
      float: left;
      margin-left: 46px;
      margin-top: 20px;
    }
    .are {
      margin-left: -40px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 16px;
      padding: 0 !important;
    }
    .service_main .orderscentre {
      width: 48px;
      height: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 26px;
      margin-left: 13px;
      margin-top: 20px;
      float: left;
    }
    .service_main_header {
      float: left;
      margin-bottom: 13px;
    }
    .service_main .anniu {
      width: 93px;
      height: 24px;
      /* background: #339C9B; */
      background: #7c969b;
      border-radius: 16px;
      margin-left: 791px;
      margin-top: 20px;
      line-height: 0px;
      color: #ffffff;
      padding: 0;
      font-size: 12px;
    }
    .anniu span {
      width: 48px;
      height: 13px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      line-height: 26px;
    }
    .activity {
      float: left;
      margin: 13px 33px 13px 13px;
    }
    .activity2 {
      float: left;
      margin: 0px 33px 13px 13px;
    }
    .search {
      width: 66px;
      float: left;
      margin-top: 26px;
    }
    .search_div {
      width: 26px;
      height: 26px;
      float: left;
      border-radius: 50%;
      background: #339c9b;
    }
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 2px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .upload_box i {
      font-size: 14px;
      line-height: 40px;
      color: #7c969b;
    }
    .activit {
      float: left;
      width: 882px;
    }
    .search i {
      line-height: 26px;
      font-size: 13px;
      color: #ffffff;
      margin-left: 6px;
    }
    .search_div2 {
      width: 26px;
      height: 26px;
      float: left;
      border-radius: 50%;
      background: #7c969b;
      margin-left: 13px;
    }
    .upload-demo {
      padding: 0px;
      float: left;
      width: 191px;
    }
    #wdrz .upload-demo {
      padding: 0px;
      float: left;
      width: 181px;
    }
    >>> .f2 .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
    >>> #jzfw .el-form-item__label {
      width: 75px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 5px;
      margin-right: 6px;
      margin-bottom: 5px;
    }

    >>> .f2 .el-input.is-disabled .el-input__inner {
      width: 196px;
      height: 26px;
    }
    >>> #jzfw .el-input.is-disabled .el-input__inner {
      width: 202px;
      height: 26px;
    }

    >>> #wdrz .f2 .el-input.is-disabled .el-input__inner {
      margin-left: -5px;
      width: 250px;
      height: 26px;
    }
    .retained_profits3 .upload-demo {
      padding: 0px;
      float: left;

      width: 191px;
    }
    .wz {
      font-size: 8px;
      font-family: MicrosoftYaHei;
      color: #92a2bc;
      line-height: 10px;
      margin-left: 13px;
      float: left;
      width: 188px;
    }
    >>> .anniu2 .qiuxiao {
      width: 106px;
      height: 26px;
      background: #7c969b;
      border-radius: 21px;
      padding: 0;
    }
    >>> .anniu2 .qiuxiao span {
      padding: 0;
    }
    >>> .anniu2 .tijiao {
      width: 106px;
      height: 26px;
      background: #339c9b;
      border-radius: 21px;
      padding: 0;
    }
    >>> .el-input__inner {
      width: 200px;
      height: 20px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    >>> #wdrz .f2 .el-form-item__label {
      width: 129px !important;
      height: 16px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-right: 6px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0px;
    }
    >>> .activity2 .el-input__inner {
      width: 185px !important;
      height: 20px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    .el-form-item >>> .el-date-editor .el-range__icon {
      position: absolute;
      left: 49%;
      top: -7px;
      transform: translateX(-50%);
    }
    >>> .el-form-item__label {
      width: 63px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
    >>> .el-dialog__footer {
      text-align: left;
      margin-left: 0px;
      padding-top: 0px;
      padding-left: 46px;
      padding-right: 46px;
      padding-bottom: 27px;
    }
    >>> .el-radio {
      margin-top: 5px;
    }
    >>> .el-form-item__content {
      float: left;
      margin: 0 !important;
    }
    >>> #sczs .el-form-item__content > div {
      width: 191px;
      float: left;
      margin-left: -40px;
      width: 200px;
    }
    /* >>>#wdrz .el-form-item__content>div{
                                                                                                                                                                                                                                                                                                                                                  width: 200px;
                                                                                                                                                                                                                                                                                                                                                    float: left;
                                                                                                                                                                                                                                                                                                                                                } */
    >>> #wdrz .el-upload__tip {
      height: 16px;
      float: left;
      margin: 0;
      position: static;
      transform: scale(0.55);
      position: absolute;
      top: 25px;
      right: -10px;
      font-size: 12px;
      width: 210px;
    }
    >>> .el-table {
      margin: 13px 13px 6px 13px;
      width: 933px !important;
    }
    >>> .el-table th > .cell {
      text-align: center;
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
    }
    >>> .el-upload__tip {
      height: 16px;
      float: left;
      margin: 0;
      position: static;
      transform: scale(0.75);
      position: absolute;
      top: 25px;
      right: -20px;
      font-size: 12px;
    }
    >>> .el-table .cell {
      text-align: center;
      height: 40px;
      line-height: 40px;
    }
    >>> .el-pagination__editor.el-input .el-input__inner {
      width: 36px;
      height: 17px !important;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(12, 79, 71, 0.16);
      border-radius: 13px;
    }
    >>> .el-upload-list--picture-card .el-upload-list__item {
      width: 40px;
      height: 40px;
      margin: 0 8px 0 0;
    }
    >>> .el-pagination {
      margin-right: 13px;
    }
    >>> .el-input__icon.el-icon-date {
      left: 42%;
      position: absolute;
      top: -7px;
    }
    >>> .el-date-editor .el-range-separator {
      display: none;
    }
    /* >>>.iconfont.el-tooltip:nth-child(n){
                                                                                                                                                                                                                                                                                                                                                    color: #339C9B;
                                                                                                                                                                                                                                                                                                                                                    height: 13px !important;
                                                                                                                                                                                                                                                                                                                                                    font-size: 13px;
                                                                                                                                                                                                                                                                                                                                                } */
    >>> .certification .el-dialog {
      width: 333px !important;
      height: 714px !important;
      background: #ffffff;
      box-shadow: 20px 40px 70px 0px rgba(12, 79, 71, 0.08);
      border-radius: 6px;
    }
    >>> .el-dialog__header {
      text-align: center;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 16px;
      padding-bottom: 0px;
      padding-top: 20px;
    }
    >>> .certification .el-dialog__body {
      padding: 0;
    }
    >>> .retained_profits {
      margin-bottom: 13px !important;
    }
    >>> .retained_profits2 {
      margin-bottom: 13px !important;
    }
    >>> .retained_profits3 {
      margin-bottom: 0px !important;
    }

    >>> .certification .retained_profits4 {
      margin-top: 5px !important;
    }
    >>> .certification .retained_profits .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0 !important;
      margin-right: 6px;
    }
    >>> .certification #sczs .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 5px;
      margin-bottom: 18px;
      padding: 0 !important;
      margin-right: 6px;
    }
    >>> .certification .retained_profits2 .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0 !important;
      margin-right: 6px;
    }
    >>> .certification .retained_profits5 .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 0 !important;
      margin-right: 6px;
    }
    >>> .certification .retained_profits3 .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 25px;
      margin-bottom: 25px;
      padding: 0 !important;
      margin-right: 6px;
    }
    >>> .certification .retained_profits4 .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      padding: 0 !important;
      margin-right: 6px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    >>> .certification .mc .el-input__inner {
      width: 191px;
      height: 26px;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    >>> .certification .el-upload-list {
      margin-top: 0px;
    }
    >>> .certification .el-upload-list li {
      float: left;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background: #f7fafc;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    >>> .certification .el-upload-list__item-name [class^='el-icon'] {
      width: 17px;
      height: 20px;
    }
    >>> .certification .retained_profits3 .el-upload {
      display: none;
    }
    >>> #scbg .el-upload-list {
      margin: 0;
    }
    >>> .retained_profits .el-upload--picture-card {
      width: 40px;
      height: 40px;
      background: #f7fafc;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    >>> .retained_profits .el-upload--picture-card i {
      bottom: 55px;
    }
    >>> .monitortrace .el-dialog {
      width: 333px !important;
      height: 409px;
      background: #ffffff;
      box-shadow: 20px 40px 70px 0px rgb(12 79 71 / 8%);
      border-radius: 6px;
    }
    >>> .monitortrace .el-form-item__label {
      width: 75px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 18px;
      padding: 0;
      margin-right: 6px;
    }
    >>> #scbg .el-form-item__content > div {
      float: left;
    }
    >>> .monitortrace .el-input__icon.el-icon-date {
      top: 1.5px;
      transform: scale(1);
      margin-left: 183px;
    }
    >>> .monitortrace .el-input__suffix {
      display: none;
    }
    >>> .monitortrace .el-dialog__body {
      padding: 0px;
    }
    >>> .monitortrace .el-input__inner {
      width: 196px;
      height: 26px;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    >>> .monitortrace .el-textarea {
      border: 1px solid red;
      width: 246px;
      height: 53px;
    }
    >>> .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 200px;
    }
    >>> .monitortrace .el-dialog .el-textarea__inner {
      width: 246px;
      height: 53px;
      border-radius: 2px;
    }
    >>> .legalaid .el-form-item__label {
      width: 64px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 18px;
      padding: 0;
      margin-right: 6px;
    }
    >>> .legalaid .el-input__icon.el-icon-date {
      top: 1px;
      transform: scale(1.6);
      margin-left: 208px;
    }
    >>> .legalaid .el-input__suffix {
      display: none;
    }
    >>> .legalaid .el-input__inner {
      width: 235px;
      height: 26px;
      border-radius: 2px;
      border: 1px solid #e1e7ea;
    }
    >>> .legalaid .el-textarea {
      width: 235px;
      height: 53px;
    }
    >>> .legalaid .el-dialog .el-textarea__inner {
      width: 235px;
      height: 65px;
      border-radius: 2px;
    }
    >>> .retained_profits2 .el-upload--picture-card i {
      font-size: 14px;
      color: #7c969b;
      position: relative;
      bottom: 54px;
      text-align: center;
    }
    .tishi {
      display: inline-block;
      zoom: 0.5;
      color: #92a2bc;
      margin-top: 40px;
    }
    >>> .el-radio__input.is-checked + .el-radio__label {
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
    }
    >>> .el-radio__label {
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
    }
    #rz.certification .retained_profits .el-form-item__label {
      width: 58px !important;
      height: 10px;
      font-size: 6px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 8px;
      margin-top: 3px;
      padding: 0 !important;
      margin-right: 4px;
      border: 1px solid;
    }
    >>> #rz .el-form-item__label {
      width: 87px !important;
      height: 16px;
      font-size: 9px;
      font-family: MicrosoftYaHei;
      color: #162747;
      line-height: 12px;
      margin-top: 5px;
      padding: 0 !important;
      margin-right: 6px;
      margin-bottom: 5px;
    }
    >>> #scbg .el-upload--picture-card i {
      font-size: 14px;
      color: #7c969b;
      position: relative;
      bottom: 54px;
      text-align: center;
    }
    .qiuxiao {
      width: 106px;
      height: 26px;
      background: #7c969b;
      border-radius: 21px;
      float: left;
      padding: 0;
    }
    .tijiao {
      width: 106px;
      height: 26px;
      background: #339c9b;
      border-color: #339c9b;
      border-radius: 21px;
      padding: 0;
    }
    .tijiaok {
      margin-left: 26px;
    }

    .anniudk {
      float: left;
      padding: 14px 113px 14px 113px;

      width: 333px;

      display: flex;
    }
    .anniudk2 {
      padding: 14px 46px 26px 46px;
      width: 333px;
      display: flex;
    }
    >>> #sczs .el-upload--picture-card {
      width: 40px;
      height: 40px;
      margin-right: 4px;
      border: 1px solid #f7fafc;
      background-color: #f7fafc;
      border-radius: 3px;
    }
    >>> #sczs .el-upload--picture-card i {
      font-size: 14px;
      color: #7c969b;
      position: relative;
      bottom: 54px;
      text-align: center;
    }
    ::v-deep #wdrz .el-dialog__body {
      padding-top: 0 !important;
      padding-left: 20px !important;
      padding-right: 14px !important;
      padding-bottom: 13px !important;
    }
  }
</style>